(function () {
	'use strict';

	angular
		.module('common')
		.factory('onboardingTemplate', [
			'$q',
			'$translate',
			'seedcodeCalendar',
			'utilities',
			'dataStore',
			'serviceAppointmentOnboarding',
			onboardingTemplate,
		]);

	function onboardingTemplate(
		$q,
		$translate,
		seedcodeCalendar,
		utilities,
		dataStore,
		serviceAppointmentOnboarding
	) {
		return {
			initialize: initialize,
			generateSalesforceSources: generateSalesforceSources,
		};
		function initialize(userType, userID, parentGroupID, userOnly) {
			var platform = utilities.getDBKPlatform();
			if (platform === 'dbksf') {
				return salesforce(userType, userID);
			} else if (platform === 'dbkfmjs' || platform === 'dbkfmwd') {
				return fileMakerJS(userType, userID, parentGroupID, userOnly);
			} else {
				return dbko(userType, userID);
			}
		}

		function createActivation(userID) {
			return {
				trialStart: true,
				state: crypt.getTrialString(),
				currentPeriodEndsAt: crypt.createTrialExpirationDate(),
				created: moment().format(),
				usersPermitted: 1,
				userID: userID,
			};
		}
		function generateSalesforceSources(sources, sourceTypeID) {
			//Define sources
			//Salesforce Data Sources
			sources['0-default-source-10'] = {
				id: '0-default-source-10',
				allowAllDay: true,
				backgroundColor: 'rgba(109, 197, 227, 0.85)',
				contactObjects: {
					'0-default-contact-object-1': {
						id: '0-default-contact-object-1',
						displayField: 'Name',
						object: 'Contact',
						objectDisplay: 'Contact',
						searchField: 'Name',
					},
					'0-default-contact-object-2': {
						id: '0-default-contact-object-2',
						displayField: 'Name',
						object: 'Lead',
						objectDisplay: 'Lead',
						searchField: 'Name',
					},
				},
				customActions:
					sourceTypeID !== 4
						? null
						: {
								'0-default-custom-action-1': {
									id: '0-default-custom-action-1',
									name: 'Go To Event',
									newWindow: false,
									preventDefault: false,
									type: '',
									url: "fbk.navigate('[[Id]]')",
									eventType: {editable: true},
								},
							},
				fieldMap: {
					allDay: 'IsAllDayEvent',
					contactID: 'WhoId',
					contactName: 'Who.Name',
					description: 'Description',
					end: 'EndDateTime',
					eventID: 'Id',
					projectID: 'WhatId',
					projectName: 'What.Name',
					resource: 'Owner.Name',
					resourceID: 'OwnerId',
					start: 'StartDateTime',
					status: 'ShowAs',
					title: 'Subject,Who.Name,What.Name',
					titleEdit: 'Subject',
					location: 'Location',
				},
				isPrimary: false,
				labelMap: {
					contactName: 'Name',
					projectName: 'Related To',
					resource: 'Assigned To',
					status: 'Show As',
					titleEdit: 'Subject',
				},
				name: 'Events',
				objectName: 'Event',
				projectObjects: {
					'0-default-project-object-1': {
						id: '0-default-project-object-1',
						displayField: 'Name',
						object: 'Account',
						objectDisplay: 'Account',
						searchField: 'Name',
					},
					'0-default-project-object-2': {
						id: '0-default-project-object-2',
						displayField: 'Name',
						object: 'Campaign',
						objectDisplay: 'Campaign',
						searchField: 'Name',
					},
					'0-default-project-object-3': {
						id: '0-default-project-object-3',
						displayField: 'CaseNumber,Subject',
						object: 'Case',
						objectDisplay: 'Case',
						searchField: 'Subject',
					},
					'0-default-project-object-4': {
						id: '0-default-project-object-4',
						displayField: 'Name',
						object: 'Opportunity',
						objectDisplay: 'Opportunity',
						searchField: 'Name',
					},
					'0-default-project-object-5': {
						id: '0-default-project-object-5',
						displayField: 'Name',
						object: 'Product2',
						objectDisplay: 'Product',
						searchField: 'Name',
					},
				},
				readOnly: false,
				sourceTypeID: sourceTypeID,
				unusedMap: {
					tags: true,
				},
			};

			sources['0-default-source-11'] = {
				id: '0-default-source-11',
				allowAllDay: false,
				backgroundColor: 'rgba(230, 141, 100, 0.85)',
				contactObjects: {
					'0-default-contact-object-1': {
						id: '0-default-contact-object-1',
						displayField: 'Name',
						object: 'Contact',
						objectDisplay: 'Contact',
						searchField: 'Name',
					},
					'0-default-contact-object-2': {
						id: '0-default-contact-object-2',
						displayField: 'Name',
						object: 'Lead',
						objectDisplay: 'Lead',
						searchField: 'Name',
					},
				},
				customActions:
					sourceTypeID !== 4
						? null
						: {
								'0-default-custom-action-1': {
									id: '0-default-custom-action-1',
									name: 'Go To Task',
									newWindow: false,
									preventDefault: false,
									type: '',
									url: "fbk.navigate('[[Id]]')",
									eventType: {editable: true},
								},
							},
				fieldMap: {
					allDay: '',
					contactID: 'WhoId',
					contactName: 'Who.Name',
					description: 'Description',
					end: 'ActivityDate',
					eventID: 'Id',
					projectID: 'WhatId',
					projectName: 'What.Name',
					resource: 'Owner.Name',
					resourceID: 'OwnerId',
					start: 'ActivityDate',
					status: 'Status',
					title: 'Subject,Who.Name,What.Name',
					titleEdit: 'Subject',
				},
				isPrimary: false,
				labelMap: {
					contactName: 'Name',
					projectName: 'Related To',
					resource: 'Assigned To',
					start: 'Due Date',
				},
				name: 'Tasks',
				objectName: 'Task',
				projectObjects: {
					'0-default-project-object-1': {
						id: '0-default-project-object-1',
						displayField: 'Name',
						object: 'Account',
						objectDisplay: 'Account',
						searchField: 'Name',
					},
					'0-default-project-object-2': {
						id: '0-default-project-object-2',
						displayField: 'Name',
						object: 'Campaign',
						objectDisplay: 'Campaign',
						searchField: 'Name',
					},
					'0-default-project-object-3': {
						id: '0-default-project-object-3',
						displayField: 'CaseNumber,Subject',
						object: 'Case',
						objectDisplay: 'Case',
						searchField: 'Subject',
					},
					'0-default-project-object-4': {
						id: '0-default-project-object-4',
						displayField: 'Name',
						object: 'Opportunity',
						objectDisplay: 'Opportunity',
						searchField: 'Name',
					},
					'0-default-project-object-5': {
						id: '0-default-project-object-5',
						displayField: 'Name',
						object: 'Product2',
						objectDisplay: 'Product',
						searchField: 'Name',
					},
				},
				readOnly: false,
				sourceTypeID: sourceTypeID,
				unusedMap: {
					allDay: false,
					end: true,
					location: true,
					tags: true,
				},
			};

			sources['0-default-source-12'] = {
				id: '0-default-source-12',
				allowAllDay: false,
				backgroundColor: 'rgba(242, 238, 111, 0.85)',
				customActions:
					sourceTypeID !== 4
						? null
						: {
								'0-default-custom-action-1': {
									id: '0-default-custom-action-1',
									name: 'Go To Campaign',
									newWindow: false,
									preventDefault: false,
									type: '',
									url: "fbk.navigate('[[Id]]')",
									eventType: {editable: true},
								},
							},
				fieldMap: {
					allDay: '',
					contactID: '',
					contactName: '',
					description: 'Description',
					end: 'EndDate',
					eventID: 'Id',
					projectID: 'ParentId',
					projectName: 'Parent.Name',
					resource: 'Owner.Name',
					resourceID: 'OwnerId',
					start: 'StartDate',
					status: 'Status',
					title: 'Name',
					titleEdit: 'Name',
				},
				isPrimary: false,
				labelMap: {
					contactName: '',
					end: 'End',
					projectName: 'Parent Campaign',
					resource: 'Campaign Owner',
					start: 'Start',
					titleEdit: 'Name',
				},
				name: 'Campaigns',
				objectName: 'Campaign',
				projectObjects: {
					'0-default-project-object-1': {
						id: '0-default-project-object-1',
						displayField: 'Name',
						object: 'Campaign',
						objectDisplay: 'Parent Campaign',
						searchField: 'Name',
					},
				},
				readOnly: false,
				sourceTypeID: sourceTypeID,
				unusedMap: {
					allDay: true,
					contactID: true,
					contactName: true,
					end: false,
					projectID: false,
					projectName: false,
					location: true,
					tags: true,
				},
			};
		}

		function generateServiceAppointmentsSource(sources, sourceTypeID) {
			sources['0-default-service-source-10'] = {
				id: '0-default-service-source-10',
				allowAllDay: true,
				allowDayback: true,
				allowRelated: true,
				allowUnscheduled: false,
				backgroundColor: 'rgba(175, 108, 189, 0.85)',
				contactObjects: {
					'1710353651934-8613351296': {
						displayField: 'Name',
						id: '1710353651934-8613351296',
						object: 'Account',
						objectDisplay: 'Account',
						searchField: 'Name',
					},
				},
				customActions: {
					'1710354142014-1948848448': {
						eventType: {
							editable: true,
						},
						id: '1710354142014-1948848448',
						name: 'Goto Appointment',
						newWindow: false,
						preventDefault: false,
						type: '',
						url: 'dbk.navigate(editEvent.eventID);',
					},
				},
				eventActions: serviceAppointmentOnboarding.getEventActions(),
				fieldMap: {
					contactID: 'ParentRecordId',
					contactName: 'ParentRecord.Name',
					description: 'Description',
					end: 'SchedEndTime',
					location: '',
					projectID: 'WorkTypeId',
					projectName: 'WorkType.Name',
					resource: 'resource',
					start: 'SchedStartTime',
					status: 'Status',
					tags: '',
					title: 'Subject, Description',
					titleEdit: 'Subject',
					unscheduled: '',
				},
				fileDateFormat: 'YYYY-MM-DD',
				includeFilters: false,
				isMeasureOnly: false,
				isPrimary: false,
				isServiceAppointment: true,
				isUnavailable: false,
				labelMap: {
					contactName: 'Account',
					projectName: 'Work Type',
				},
				name: 'Service Appointments',
				objectName: 'ServiceAppointment',
				preFilter: '',
				projectObjects: {
					'1710354116479-8126425170': {
						displayField: 'Name',
						id: '1710354116479-8126425170',
						object: 'WorkType',
						objectDisplay: 'Work Type',
						searchField: 'Name',
					},
				},
				readOnly: false,
				recordType: '',
				relatedFirstWord: false,
				resource: 'AssignedResources',
				sourceTypeID: 4,
				unusedMap: {
					allDay: true,
					location: true,
					tags: true,
				},
				useAssignedResources: true,
			};
		}

		// Salesforce Onboarding
		async function salesforce(userType, userID) {
			//explicitly clear resourcefolders expanded
			dataStore.saveState('resourceFolderExpanded', '[]');

			var deferred = $q.defer();
			// Initialize app actions;
			var calendarActions;

			//Define settings
			var settings = {
				activation: createActivation(userID),
				sourceTypes: {
					googleCalendar: {
						id: 3,
					},
					salesforce: {
						id: 4,
					},
					office365: {
						id: 5,
					},
				},
				view: 'agendaResourceVert',
				resourceColumns: '4',
				resourceDays: '3',
			};

			//case 52742 //case 49192
			//Add the first user to the admin list so we don't have a situation where nobody is an admin
			var account = fbk.getUserInfo('accountName');
			settings.adminAccountList = account;

			//Define sources
			var sources = {};
			var statuses;

			try {
				// Check to see if this org has service appointments set up
				await fbk.objectInfo('ServiceAppointment');
				generateServiceAppointmentsSource(sources, 4);
				calendarActions = serviceAppointmentOnboarding.getAppActions();
				//Define statuses
				statuses = {
					'0-default-status-1': {
						id: '0-default-status-1',
						name: 'Scheduled',
						color: 'rgba(128,163,254,0.85)',
					},
					'0-default-status-2': {
						id: '0-default-status-2',
						name: 'Dispatched',
						color: 'rgba(250,192,127,0.85)',
					},
					'0-default-status-3': {
						id: '0-default-status-3',
						name: 'In Progress',
						color: 'rgba(230,255,202,0.85)',
					},
					'0-default-status-4': {
						id: '0-default-status-4',
						name: 'Cannot Complete',
						color: 'rgba(254,128,129,0.85)',
					},
					'0-default-status-5': {
						id: '0-default-status-5',
						name: 'Completed',
						color: 'rgba(44,163,70,0.87)',
					},
					'0-default-status-6': {
						id: '0-default-status-6',
						name: 'Canceled',
						color: 'rgba(133,133,133,0.85)',
					},
					'0-default-status-7': {
						id: '0-default-status-7',
						name: 'Checked In',
						color: 'rgba(219,118,186,0.49)',
					},
					'0-default-status-8': {
						id: '0-default-status-8',
						name: 'No-Show',
						color: 'rgba(255,51,51,0.85)',
					},
				};
			} catch (err) {
				generateSalesforceSources(sources, 4);
				//Define statuses
				const eventStatuses = {
					'0-default-event-folder-1': {
						id: '0-default-event-folder-1',
						folderID: '0-default-event-folder-1',
						isFolder: true,
						name: 'Events',
					},
					'0-default-event-status-1': {
						id: '0-default-event-status-1',
						folderID: '0-default-event-folder-1',
						name: 'Busy',
						color: 'rgba(230,255,202,0.85)',
					},
					'0-default-event-status-2': {
						id: '0-default-event-status-2',
						folderID: '0-default-event-folder-1',
						name: 'Out of Office',
						color: 'rgba(255,51,51,0.85)',
					},
					'0-default-event-status-3': {
						id: '0-default-event-status-3',
						folderID: '0-default-event-folder-1',
						name: 'Free',
						color: 'rgba(128,163,254,0.85)',
					},
				};

				const taskStatuses = {
					'0-default-task-folder-1': {
						id: '0-default-task-folder-1',
						folderID: '0-default-task-folder-1',
						isFolder: true,
						name: 'Tasks',
					},
					'0-default-task-status-1': {
						id: '0-default-task-status-1',
						folderID: '0-default-task-folder-1',
						name: 'Not Started',
						color: 'rgba(254,128,129,0.85)',
					},
					'0-default-task-status-2': {
						id: '0-default-task-status-2',
						folderID: '0-default-task-folder-1',
						name: 'In Progress',
						color: 'rgba(230,255,202,0.85)',
					},
					'0-default-task-status-3': {
						id: '0-default-task-status-3',
						folderID: '0-default-task-folder-1',
						name: 'Completed',
						color: 'rgba(44,163,70,0.87)',
					},
					'0-default-task-status-4': {
						id: '0-default-task-status-4',
						folderID: '0-default-task-folder-1',
						name: 'Waiting on someone else',
						color: 'rgba(219,118,186,0.49)',
					},
					'0-default-task-status-5': {
						id: '0-default-task-status-5',
						folderID: '0-default-task-folder-1',
						name: 'Deferred',
						color: 'rgba(250,192,127,0.85)',
					},
				};
				statuses = {...eventStatuses, ...taskStatuses};
			}

			//Google Calendar Source
			sources['0-default-source-4'] = {
				id: '0-default-source-4',
				isPrimary: false,
				name: 'Google Calendar',
				readOnly: false,
				sourceTypeID: 3,
			};

			var userLimit = 100;
			var userFolderId = '0-default-resource-folder-1';
			var adminFolderId = '0-default-resource-folder-2';
			var resources = {};
			resources[userFolderId] = {
				id: userFolderId,
				folderID: userFolderId,
				name: '',
				isFolder: true,
			};

			//initial resources
			const adminUsers = await fbk.systemAdmins();

			if (adminUsers.length > 0) {
				resources[adminFolderId] = {
					id: adminFolderId,
					folderID: adminFolderId,
					name: 'People - Admins',
					isFolder: true,
				};
			}
			for (var i = 0; i < adminUsers.length; i++) {
				var id = adminUsers[i].Id.substring(0, 15) + '-A' + i;

				// Don't add automated process user
				if (adminUsers[i].Name === 'Automated Process') {
					continue;
				}

				resources[id] = {
					id: id,
					name: adminUsers[i].Name,
					shortName: adminUsers[i].FirstName
						? adminUsers[i].FirstName.substring(0, 1) +
							adminUsers[i].LastName.substring(0, 1)
						: adminUsers[i].Alias,
					folderID: adminFolderId,
				};
			}
			const users = await fbk.users(userLimit);
			for (var i = 0; i < users.length; i++) {
				var id = users[i].Id.substring(0, 15) + '-U' + i;

				// Don't add automated process user
				if (users[i].Name === 'Automated Process') {
					continue;
				}

				resources[id] = {
					id: id,
					name: users[i].Name,
					shortName: users[i].FirstName
						? users[i].FirstName.substring(0, 1) +
							users[i].LastName.substring(0, 1)
						: users[i].Alias,
					folderID: userFolderId,
				};
			}
			// Assign user folder name
			resources[userFolderId].name =
				users.length === userLimit
					? 'People - First ' + userLimit.toString() + ' Users'
					: 'People - Users';

			//check if allDay is available
			let fields = [];
			try {
				fields = await fbk.fields('Event');
			} catch (err) {
				fields = [];
			}

			var allDay = false;
			for (var i = 0; i < fields.length; i++) {
				if (fields[i].apiName === 'IsAllDayEvent') {
					allDay = true;
				}
			}
			if (!allDay) {
				//update the Event schedule to disable allDay if disabled for this org.
				if (sources['0-default-source-10']) {
					sources['0-default-source-10'].unusedMap.allDay = true;
					sources['0-default-source-10'].fieldMap.allDay = '';
				}
			}
			//Clean up sources based on available objects
			const objectsResult = await fbk.objects();
			if (objectsResult && !objectsResult.errorCode) {
				//make sure these stock salesforce sources exist in salesforce for this org before setting them as defaults
				for (var property in sources) {
					if (
						sources[property].sourceTypeID === 4 &&
						!fbk.objectExists(
							sources[property].objectName,
							objectsResult
						)
					) {
						delete sources[property];
					}
				}
			}

			// Add additional resources
			generateRoomResources(resources);
			generateTruckResources(resources);

			//Return defined data
			const result = {
				settings: settings,
				sources: sources,
				resources: resources,
				statuses: statuses,
				calendarActions: calendarActions,
			};

			deferred.resolve(result);

			return deferred.promise;
		}

		// Standard DBKO Onboarding
		function dbko(userType, userID) {
			var deferred = $q.defer();
			//Define settings
			var settings = {
				activation: createActivation(userID),
				sourceTypes: {
					filemakerServer: {
						id: 2,
					},
					googleCalendar: {
						id: 3,
					},
					office365: {
						id: 5,
					},
					salesforceAPI: {
						id: 10,
					},
				},
			};

			//Define sources
			var sources = {
				//Google Calendar Source
				'0-default-source-1': {
					id: '0-default-source-1',
					isPrimary: false,
					name: 'Google Calendar',
					readOnly: false,
					sourceTypeID: 3,
				},
			};

			//Define resources
			var resources = {};

			generatePeopleResources(resources);
			generateRoomResources(resources);
			generateTruckResources(resources);

			//Define Statuses
			var statuses = {
				'0-default-status-1': {
					id: '0-default-status-1',
					name: 'Begun',
					color: 'rgba(230,255,202,0.85)',
				},
				'0-default-status-2': {
					id: '0-default-status-2',
					name: 'Paused: Errors',
					color: 'rgba(250,192,127,0.85)',
				},
				'0-default-status-3': {
					id: '0-default-status-3',
					name: 'Cancelled',
					color: 'rgba(199,199,199,0.85)',
				},
				'0-default-status-4': {
					id: '0-default-status-4',
					name: 'Paused: Questions',
					color: 'rgba(219,118,186,0.49)',
				},
				'0-default-status-5': {
					id: '0-default-status-5',
					name: 'Done',
					color: 'rgba(129,167,212,0.85)',
				},
				'0-default-status-6': {
					id: '0-default-status-6',
					name: 'Awaiting Approval',
					color: 'rgba(254,128,129,0.85)',
				},
				'0-default-status-7': {
					id: '0-default-status-7',
					name: 'Ready',
					color: 'rgba(44,163,70,0.87)',
				},
			};

			var result = {
				settings: settings,
				sources: sources,
				resources: resources,
				statuses: statuses,
			};

			//Modify for specific user types
			if (userType === 'google') {
				googleSpecific(result);
			} else if (userType === 'sharing') {
				sharingSpecific(result);
			} else if (userType === 'filemaker-client') {
				filemakerClientSpecific(result);
			} else if (userType === 'office365') {
				office365Specific(result);
			} else {
				noRouteSpecified(result);
			}

			deferred.resolve(result);

			function noRouteSpecified(result) {
				googleSpecific(result);
			}

			function googleSpecific(result) {
				//set disabled sources
				result.settings.sourceTypes.filemakerServer.disabled = true;
			}

			function office365Specific(result) {
				//set disabled sources
				result.settings.sourceTypes.filemakerServer.disabled = true;

				// Set office 365 source
				result.settings.sourceTypes['office365'] = {id: 5};

				result.sources['0-default-source-o365'] = {
					id: '0-default-source-o365',
					isPrimary: false,
					name: 'Microsoft 365',
					readOnly: false,
					sourceTypeID: 5,
				};
			}

			function sharingSpecific(result) {
				//set disabled sources
				result.settings.sourceTypes.googleCalendar.disabled = true;
			}

			function filemakerClientSpecific(result) {
				//set disabled sources
				result.settings.sourceTypes.googleCalendar.disabled = true;
				result.settings.sourceTypes.filemakerServer.disabled = true;
			}

			return deferred.promise;
		}

		function applyResources(resourceList, newResources) {
			for (var property in newResources) {
				resourceList[property] = newResources[property];
			}
		}

		function generatePeopleResources(resources) {
			var folderID = '0-default-people-resource-folder';
			var peopleResources = {};

			// Set folder
			peopleResources[folderID] = {
				id: folderID,
				folderID: folderID,
				name: 'My Team',
				isFolder: true,
				sort: 2,
			};

			// Set resources
			peopleResources['0-default-people-resource-1'] = {
				id: '0-default-people-resource-1',
				name: 'Jim Reynolds',
				shortName: 'JR',
				folderID: folderID,
				sort: 3,
			};

			peopleResources['0-default-people-resource-2'] = {
				id: '0-default-people-resource-2',
				name: 'Amy Brown',
				shortName: 'AB',
				folderID: folderID,
				sort: 4,
			};

			peopleResources['0-default-people-resource-3'] = {
				id: '0-default-people-resource-3',
				name: 'Dr. Smith',
				shortName: 'DS',
				folderID: folderID,
				sort: 5,
			};

			// Add the resources to the supplied resource list
			applyResources(resources, peopleResources);
		}

		function generateRoomResources(resources) {
			var folderID = '0-default-rooms-resource-folder';

			var roomsResources = {};

			// Set folder
			roomsResources[folderID] = {
				id: folderID,
				folderID: folderID,
				name: 'Rooms',
				isFolder: true,
				sort: 10,
			};

			roomsResources['0-default-rooms-resource-1'] = {
				id: '0-default-rooms-resource-1',
				name: 'Room 1',
				shortName: 'R1',
				folderID: folderID,
				sort: 11,
			};

			roomsResources['0-default-rooms-resource-2'] = {
				id: '0-default-rooms-resource-2',
				name: 'Room 2',
				shortName: 'R2',
				folderID: folderID,
				sort: 12,
			};

			roomsResources['0-default-rooms-resource-3'] = {
				id: '0-default-rooms-resource-3',
				name: 'Room 3',
				shortName: 'R3',
				folderID: folderID,
				sort: 13,
			};

			// Add the resources to the supplied resource list
			applyResources(resources, roomsResources);
		}

		function generateTruckResources(resources) {
			var folderID = '0-default-trucks-resource-folder';
			var truckResources = {};

			// Set folder
			truckResources[folderID] = {
				id: folderID,
				folderID: folderID,
				name: 'Trucks',
				isFolder: true,
				sort: 6,
			};

			// Set resources
			truckResources['0-default-trucks-resource-1'] = {
				id: '0-default-trucks-resource-1',
				name: 'Truck 1',
				shortName: 'T1',
				folderID: folderID,
				sort: 7,
			};

			truckResources['0-default-trucks-resource-2'] = {
				id: '0-default-trucks-resource-2',
				name: 'Truck 2',
				shortName: 'T2',
				folderID: folderID,
				sort: 8,
			};

			truckResources['0-default-trucks-resource-3'] = {
				id: '0-default-trucks-resource-3',
				name: 'Truck 3',
				shortName: 'T3',
				folderID: folderID,
				sort: 9,
			};

			// Add the resources to the supplied resource list
			applyResources(resources, truckResources);
		}

		function fileMakerJS(userType, userID, parentGroupID, userOnly) {
			var platform = utilities.getDBKPlatform();
			var onboardingScriptTimeout;
			var deferred = $q.defer();
			//Define settings
			var settings = {
				activation: createActivation(userID),
				sourceTypes: {
					filemakerJS: {
						id: 8,
					},
					googleCalendar: {
						id: 3,
					},
					office365: {
						id: 5,
					},
					salesforceAPI: {
						id: 10,
					},
					filemakerServer: {
						id: 2,
						hidden: true,
					},
				},
			};

			//Define sources

			var sources = {
				//Google Calendar Source
				'0-default-source-1': {
					id: '0-default-source-1',
					isPrimary: false,
					name: 'Google Calendar',
					readOnly: false,
					sourceTypeID: 3,
				},
			};

			//we're in the DayBack file so add sample events and todos
			sources['0-default-source-2'] = {
				allowDayback: true,
				allowRelated: true,
				backgroundColor: 'rgba(59,210,219,0.85)',
				contactData: {
					displayField: 'SampleContacts_DayBack::NameFull',
					navigationLayout: 'Contact Detail',
					searchField: 'SampleContacts_DayBack::NameFull',
					searchLayout: 'Sample Contacts',
				},
				defaultResource: false,
				fieldMap: {
					contactID: 'id_contact',
					contactName: 'SampleContacts_DayBack::NameFull',
					dateEnd: 'DateEnd',
					dateStart: 'DateStart',
					description: 'Description',
					end: 'DBk_TimestampEndCalcNum',
					eventID: 'id',
					location: 'Location',
					projectID: 'id_Project',
					projectName: 'SampleProjects_DayBack::Name',
					resource: 'Resource',
					start: 'DBk_TimestampStartCalcNum',
					status: 'Status',
					tags: 'DBk_Tags',
					timeEnd: 'TimeEnd',
					timeStart: 'TimeStart',
					title: 'DBk_EventSummaryCalc',
					titleEdit: 'Summary',
					recurringEventID: 'DBk_Repeating_id',
				},
				fileDateFormat: 'YYYY-MM-DD',
				id: '0-default-source-2',
				isPrimary: false,
				layoutName: 'Sample Events',
				name: 'Sample Events',
				projectData: {
					displayField: 'SampleProjects_DayBack::Name',
					navigationLayout: 'Project Detail',
					searchField: 'SampleProjects_DayBack::Name',
					searchLayout: 'Project Detail',
				},
				readOnly: false,
				sourceTypeID: 8,
				tableName: 'SampleEvents_DayBack',
				contactPrimaryKey: 'SampleContacts_DayBack::id',
				projectPrimaryKey: 'SampleProjects_DayBack::id',
				customFields: {
					'dbk-additionalField-01': {
						dateFormat: '',
						decimalCharacter: '.',
						decimalPlaces: 2,
						delimiter: '',
						field: 'InvoiceAmount',
						formatas: 'currency',
						id: 'dbk-additionalField-01',
						list: '',
						name: 'Invoice Amount',
						numberLabel: '$',
						numberLabelPosition: 'before',
						thousandsSeparator: ',',
					},
					'dbk-additionalField-02': {
						dateFormat: '',
						delimiter: '',
						field: 'SpecialAttention',
						formatas: 'radio',
						id: 'dbk-additionalField-02',
						list: 'Original-Install, Rework',
						name: 'Type',
					},
				},
				customActions: {
					'1588358501072-9709497866': {
						eventType: {
							editable: true,
							readonly: true,
						},
						id: '1588358501072-9709497866',
						name: 'Go to Record',
						newWindow: false,
						preventDefault: false,
						type: '',
						url: "dbk.navigate('Event Detail', event);",
					},
					'1588358576031-7853290404': {
						eventType: {
							editable: true,
							readonly: true,
						},
						id: '1588358576031-7853290404',
						name: 'Go to dayback.com',
						newWindow: false,
						preventDefault: false,
						type: '',
						url: "dbk.performFileMakerScript('Open URL - DayBack', 'https://dayback.com');",
					},
				},
			};

			sources['0-default-source-3'] = {
				allowDayback: true,
				allowRelated: true,
				backgroundColor: 'rgba(247,210,104,0.85)',
				defaultResource: false,
				externalEdits: true,
				editLayoutName: 'ToDoList Detail',
				fieldMap: {
					contactID: '',
					contactName: '',
					dateEnd: 'DateEnd',
					dateStart: 'DateStart',
					description: 'Description',
					end: 'DBk_TimestampEndCalcNum',
					eventID: 'id',
					location: 'Location',
					projectID: '',
					projectName: '',
					resource: 'Resource',
					start: 'DBk_TimestampStartCalcNum',
					status: 'Status',
					tags: 'DBk_Tags',
					timeEnd: 'TimeEnd',
					timeStart: 'TimeStart',
					title: 'DBk_EventSummaryCalc',
					titleEdit: 'Summary',
					recurringEventID: 'DBk_Repeating_id',
				},
				fileDateFormat: 'YYYY-MM-DD',
				id: '0-default-source-3',
				includeFilters: false,
				isPrimary: false,
				layoutName: 'ToDo List',
				name: 'ToDos',
				readOnly: false,
				requireFilters: false,
				sourceTypeID: 8,
				tableName: 'SampleToDos_DayBack',
				contactPrimaryKey: '',
				projectPrimaryKey: '',
				unusedMap: {
					contactID: true,
					contactName: true,
					projectID: true,
					projectName: true,
				},
			};

			//Define resources
			var resources = {};

			generatePeopleResources(resources);
			generateRoomResources(resources);
			generateTruckResources(resources);

			//Define Statuses
			var statuses = {
				'0-default-status-1': {
					id: '0-default-status-1',
					name: 'Not started',
					color: 'rgba(203,158,247,0.85)',
					sort: 2,
				},
				'0-default-status-2': {
					id: '0-default-status-2',
					name: 'In Progress',
					color: 'rgba(230,255,202,0.85)',
					sort: 3,
				},
				'0-default-status-3': {
					id: '0-default-status-3',
					folderID: '0-default-status-3',
					name: 'Paused',
					isFolder: true,
					sort: 4,
				},
				'0-default-status-4': {
					id: '0-default-status-4',
					folderID: '0-default-status-3',
					name: 'Errors',
					color: 'rgba(137,245,219,0.85)',
					sort: 5,
				},
				'0-default-status-5': {
					id: '0-default-status-5',
					folderID: '0-default-status-3',
					name: 'Open Questions',
					color: 'rgba(240,86,181,0.85)',
					sort: 6,
				},
				'0-default-status-6': {
					id: '0-default-status-6',
					name: 'Done',
					color: 'rgba(14,91,196,0.83)',
					sort: 7,
				},
				'0-default-status-7': {
					id: '0-default-status-7',
					name: 'Cancelled',
					color: 'rgba(199,199,199,0.85)',
					sort: 8,
				},
				'0-default-status-8': {
					id: '0-default-status-8',
					name: 'Hot',
					color: 'rgba(230,71,71,0.85)',
					sort: 9,
				},
				'0-default-status-9': {
					id: '0-default-status-9',
					folderID: '0-default-status-9',
					name: 'Installations',
					isFolder: true,
					sort: 10,
				},
				'0-default-status-10': {
					id: '0-default-status-10',
					folderID: '0-default-status-9',
					name: 'Deferred',
					color: 'rgba(237,143,33,1)',
					sort: 11,
				},
				'0-default-status-11': {
					id: '0-default-status-11',
					folderID: '0-default-status-9',
					name: 'Ready',
					color: 'rgba(100,222,126,0.85)',
					sort: 12,
				},
				'0-default-status-12': {
					id: '0-default-status-12',
					folderID: '0-default-status-9',
					name: 'To Be Qualified',
					color: 'rgba(122,179,255,0.85)',
					sort: 13,
				},
			};

			var theme =
				'.Errors::before {\n\tcontent: "\\f071";\n\tfont-family: FontAwesome;\n\tcolor: red;\n\tfont-size: 16px;\n\tpadding-right: 7px;\n}\n\n.Open.Questions::before {\n\tcontent: "\\f059";\n\tfont-family: FontAwesome;\n\tcolor: blue;\n\tfont-size: 16px;\n\tpadding-right: 7px;\n}';

			var result = {
				settings: settings,
				sources: sources,
				resources: resources,
				statuses: statuses,
				theme: theme,
			};

			// Don't run date shift scriopts if in web direct
			if (platform === 'dbkfmwd') {
				deferred.resolve(result);
			} else {
				// Set a timeout in case the FileMaker scripts never run the callback
				onboardingScriptTimeout = window.setTimeout(function () {
					deferred.resolve(result);
				}, 8000);

				var scriptCount = 0;

				var translations = $translate.instant(['Moving sample data']);

				if (!parentGroupID && !userOnly) {
					utilities.showMessage(
						translations['Moving sample data'],
						null,
						null,
						'full-width'
					);

					utilities.performFileMakerScript(
						'Shift Dates in Sample Data EVENTS - DayBack',
						null,
						onboardingScriptCallback
					);
					utilities.performFileMakerScript(
						'Shift Dates in Sample Data TO-DOS - DayBack',
						null,
						onboardingScriptCallback
					);
				} else {
					deferred.resolve(result);
				}
			}

			function onboardingScriptCallback() {
				scriptCount++;
				// Complete callback routine after both scripts have executed
				if (scriptCount >= 2) {
					// Clear the timeout so we don't resolve twice
					window.clearTimeout(onboardingScriptTimeout);
					deferred.resolve(result);
				}
			}

			return deferred.promise;
		}
	}
})();
